import React, { useState } from 'react';
import { uploadBytes, getDownloadURL, ref as storageRef } from 'firebase/storage';  // Import Firebase Storage methods
import { httpsCallable } from 'firebase/functions';  // Import Firebase Cloud Functions
import { storage, functions } from '../firebase/Init';  // Import Firebase storage and functions

const BodyScan = () => {
  const [imageFile, setImageFile] = useState(null);  // Store the selected image file
  const [imageUrl, setImageUrl] = useState('');  // URL of the uploaded image
  const [metrics, setMetrics] = useState(null);  // Scored metrics from OpenAI
  const [loading, setLoading] = useState(false);  // Loading state
  const [error, setError] = useState('');  // Error message

  // Handle file selection
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setImageFile(selectedFile);  // Set the selected image file for upload
    }
  };

  // Handle image upload and set URL after upload is successful
  const uploadImage = async () => {
    if (!imageFile) {
      setError('Please select an image to upload.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // Create a reference to Firebase Storage for the image file
      const imageRef = storageRef(storage, `body-scans/${imageFile.name}`);

      // Upload the image file to Firebase Storage
      await uploadBytes(imageRef, imageFile);

      // Get the download URL of the uploaded image
      const downloadUrl = await getDownloadURL(imageRef);
      setImageUrl(downloadUrl);  // Set the image URL after successful upload
      setError('');  // Clear error if upload was successful
    } catch (uploadError) {
      console.error("Error uploading the image: ", uploadError);
      setError('Error uploading the image. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle scoring the image after upload
  const scoreImage = async () => {
    if (!imageUrl) {
      setError('Please upload an image first.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // Call the Firebase Cloud Function to analyze the body scan
      const scoreBodyScan = httpsCallable(functions, 'scoreBodyScan');
      const { data } = await scoreBodyScan({ imageUrl });

      setMetrics(data);  // Set the returned metrics from OpenAI
    } catch (error) {
      console.error("Error scoring body scan: ", error);
      setError('Error scoring the image. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-screen flex self-center place-content-center place-items-center">
      <h2 className="text-2xl font-bold text-center mb-6">Body Scan</h2>

      {/* File input for selecting an image */}
      <div className="flex flex-col items-center">
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="border p-2 mb-4 w-full max-w-sm"
        />

        {/* Button to trigger image upload */}
        <button
          onClick={uploadImage}
          disabled={loading}
          className={`bg-blue-500 text-white px-4 py-2 rounded mb-4 w-full max-w-sm ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'
            }`}
        >
          {loading ? 'Uploading...' : 'Upload Image'}
        </button>

        {/* Show image preview if upload was successful */}
        {imageUrl && (
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Uploaded Image Preview:</h3>
            <img
              src={imageUrl}
              alt="Uploaded Body Scan"
              className="border p-2 rounded max-w-sm"
            />
          </div>
        )}

        {/* Button to score the uploaded image */}
        <button
          onClick={scoreImage}
          disabled={loading || !imageUrl}
          className={`bg-green-500 text-white px-4 py-2 rounded mb-4 w-full max-w-sm ${loading || !imageUrl ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-700'
            }`}
        >
          {loading ? 'Scoring...' : 'Score Image'}
        </button>

        {/* Display error message */}
        {error && <p className="text-red-500 text-center">{error}</p>}

        {/* Display the metrics after scoring */}
        {metrics && <BodyScanResults scores={metrics} />}
      </div>
    </div>
  );
};

const BodyScanResults = ({ response }) => {
  console.log(response)
  // Assume scores is an array of objects like [{role: 'user', content: 'example message'}, {...}]
  return (
    <div className="mt-8">
      <h3 className="text-xl font-bold mb-4">Scoring Results:</h3>
      <ul className="list-disc list-inside">

        (
        <li className="text-gray-600">No results available</li>
        )
      </ul>
    </div>
  );
};

export default BodyScan;
