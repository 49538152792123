import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyASG0A6vUBRedZvACmCJnt149b9Pv0Os3k",
    authDomain: "mogged-mvp.firebaseapp.com",
    databaseURL: "https://mogged-mvp-default-rtdb.firebaseio.com",
    projectId: "mogged-mvp",
    storageBucket: "mogged-mvp.appspot.com",
    messagingSenderId: "903933903936",
    appId: "1:903933903936:web:07b957bddfd899d5f5cba5",
    measurementId: "G-0D1TD08TRC"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const storage = getStorage(app);
const functions = getFunctions();
const db = getDatabase(app); 



export { app, auth, storage, functions, db };