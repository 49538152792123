import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Step1 from './onboarding/Step1';
import Step2 from './onboarding/Step2';
import Step3 from './onboarding/Step3';

const Onboarding = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    birthday: null,
    heightFeet: '',
    heightInches: '',
    gender: '',
    weight: '',
    goal: '',
    agreementRead: false,
    isOver18: false,
  });

  // Handle form data updates
  const handleChange = (input) => (value) => {
    setFormData(prevData => ({ ...prevData, [input]: value }));
  };

  // Go to the next step
  const nextStep = () => setStep(step + 1);

  // Go to the previous step
  const prevStep = () => setStep(step - 1);

  // Animation variants
  const pageVariants = {
    initial: { opacity: 0, x: '-100%' },
    in: { opacity: 1, x: 0 },
    out: { opacity: 0, x: '100%' },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5,
  };

  // Render the appropriate step
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            nextStep={nextStep}
            handleChange={handleChange}
            formData={formData}
          />
        );
      case 2:
        return (
          <Step2
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            formData={formData}
          />
        );
      case 3:
        return (
          <Step3
            prevStep={prevStep}
            handleChange={handleChange}
            formData={formData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-screen flex items-center justify-center bg-gray-100 overflow-hidden" style={{ height: '100dvh' }}>
      <div className="w-full h-full  bg-black shadow-lg flex flex-col">
        <AnimatePresence mode="wait">
          <motion.div
            key={step}
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            className="flex-grow flex flex-col p-4 sm:p-6 overflow-hidden"
          >
            {renderStep()}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Onboarding;
