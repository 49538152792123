import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Layout from './components/Layout';
import Home from './pages/Home';
import History from './pages/History';
import Account from './pages/Account';
import Onboarding from './pages/Onboarding';
import BodyScan from './pages/BodyScan';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import NewScanModal from './components/ui/NewScanModal';
import { ScanProvider } from './contexts/ScanContext';

function App() {
  return (
    <AuthProvider>
      <ScanProvider>
        <Router>
          <Routes>
            {/* Public routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />

            <Route path="/onboarding" element={<ProtectedOnboarding />} />

            {/* Protected Layout with authenticated routes */}
            <Route path="/" element={<ProtectedLayout />}>
              <Route path="home" element={<Home />} />
              <Route path="history" element={<History />} />
              <Route path="account" element={<Account />} />
              {/* Add more authenticated routes here */}
              <Route path="*" element={<Navigate to="/home" />} />  {/* Redirect to home if no match */}
            </Route>

            {/* Catch-all redirect for unauthenticated users */}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </Router>
      </ScanProvider>
    </AuthProvider>
  );
}

const ProtectedOnboarding = () => {
  const { userLoggedIn, isOnboardingComplete } = useAuth();

  if (!userLoggedIn) {
    return <Navigate to="/login" />;  // Redirect to login if user is not authenticated
  }

  if (isOnboardingComplete) {
    return <Navigate to="/home" />;  // Redirect to home if onboarding is already complete
  }

  return <Onboarding />;  // Render the Onboarding component if user is authenticated but not complete
};

const ProtectedLayout = () => {
  const { userLoggedIn, isOnboardingComplete } = useAuth();
  const [isNewScanModalOpen, setIsNewScanModalOpen] = React.useState(false);

  if (!userLoggedIn) {
    return <Navigate to="/login" />;  // Redirect to login if user is not authenticated
  }

  if (!isOnboardingComplete) {
    return <Navigate to="/onboarding" />;  // Redirect to onboarding if not complete
  }

  return (
    <Layout
      openNewScanModal={() => setIsNewScanModalOpen(true)}
      closeNewScanModal={() => setIsNewScanModalOpen(false)}
      isNewScanModalOpen={isNewScanModalOpen}
    >
      <Routes>
        <Route path="home" element={<Home />} />
        <Route path="history" element={<History />} />
        <Route path="account" element={<Account />} />
        <Route path="scan" element={<BodyScan />} />
      </Routes>
      <NewScanModal
        isOpen={isNewScanModalOpen}
        onClose={() => setIsNewScanModalOpen(false)}
      />
    </Layout>
  );
};

export default App;
