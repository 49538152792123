import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase/Init';
import { ref, set } from 'firebase/database';  // Realtime Database imports
import { useAuth } from '../../contexts/AuthContext'

const Step3 = ({ prevStep, formData, handleChange }) => {
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { refreshOnboardingStatus } = useAuth();
  const { birthday, heightFeet, heightInches, weight, goal, agreementRead, isOver18 } = formData;

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (!agreementRead) {
      errors.agreementRead = 'You must certify that you have read and agree to the terms.';
      isValid = false;
    }

    if (!isOver18) {
      errors.isOver18 = 'You must certify that you are over 18 years old.';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      navigate('/');
      return;
    }

    try {
      const userRef = ref(db, `users/${user.uid}`);
      const age = new Date().getFullYear() - birthday.getFullYear();
      const height = `${heightFeet}'${heightInches}"`;

      await set(userRef, {
        age,
        height,
        weight,
        goal,
        agreementRead,
        isOver18,
        onboardingComplete: true,
      });

      refreshOnboardingStatus();
      navigate('/scan');
    } catch (error) {
      console.error("Error saving onboarding data: ", error);
      setErrors({ ...errors, submit: "An error occurred while saving your data. Please try again." });
    }
  };

  return (
    <div className="w-full h-screen flex self-center place-content-center place-items-center ">
      <div className="w-96 text-gray-600 space-y-5 p-4 border bg-white">
        <div className="text-center mb-6">
          <h2 className="text-gray-800 text-xl font-semibold sm:text-2xl">Step 3: Confirm Your Details</h2>
        </div>
        <ul className="space-y-2">
          <li className="text-gray-700">
            <span className="font-bold">Age:</span> {new Date().getFullYear() - birthday.getFullYear()} years
          </li>
          <li className="text-gray-700">
            <span className="font-bold">Height:</span> {heightFeet}'{heightInches}"
          </li>
          <li className="text-gray-700">
            <span className="font-bold">Weight:</span> {weight} lbs
          </li>
          <li className="text-gray-700">
            <span className="font-bold">Goal:</span> {goal}
          </li>
        </ul>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="text-sm text-gray-600 font-bold">User Agreement</label>
            <div className="mt-2 p-4 h-48 overflow-auto border bg-gray-50 text-sm">
              <h3 className="font-bold mb-2">Welcome to MOGGED!</h3>
              <p className="mb-2">
                These Terms of Use ("Terms") govern your use of the MOGGED app ("Service"). By creating an account or using the MOGGED Service, you agree to these Terms.
              </p>

              <h4 className="font-bold mt-4 mb-1">The MOGGED Service</h4>
              <p className="mb-2">
                We agree to provide you with the MOGGED Service, which includes personalized fitness evaluations, meal and workout plans, progress tracking, and social features for comparing fitness metrics.
              </p>

              <h4 className="font-bold mt-4 mb-1">Arbitration Notice</h4>
              <p className="mb-2">
                By agreeing to these Terms, you agree that disputes between you and MOGGED will be resolved through binding, individual arbitration, and you waive your right to participate in class action lawsuits or class-wide arbitration. Exceptions and opt-out details are explained below.
              </p>

              <h4 className="font-bold mt-4 mb-1">How Our Service is Funded</h4>
              <p className="mb-2">
                MOGGED is funded through a combination of user subscriptions and partnerships with fitness brands. You may see ads or sponsored content relevant to your fitness journey. We do not sell your personal data, and all data collected is used to improve your experience and offer personalized recommendations.
              </p>

              <h4 className="font-bold mt-4 mb-1">Privacy Policy</h4>
              <p className="mb-2">
                Using MOGGED requires the collection and use of your information. Please review our Privacy Policy to understand how your data is handled.
              </p>

              <h4 className="font-bold mt-4 mb-1">Your Commitments</h4>
              <ul className="list-disc pl-5 mb-2">
                <li>You must be at least 13 years old to use MOGGED.</li>
                <li>You must provide accurate and up-to-date information during registration.</li>
                <li>You may not use MOGGED for any unlawful, fraudulent, or unauthorized purposes.</li>
                <li>You may not impersonate others or misrepresent your identity.</li>
              </ul>

              <h4 className="font-bold mt-4 mb-1">Termination and Account Deletion</h4>
              <p className="mb-2">
                We may remove content or terminate your account if we believe you have violated these Terms or if your content poses a risk to other users or the platform. You may request to delete your account at any time, and the deletion process will begin within 30 days of your request.
              </p>

              <p className="mt-4">
                By checking the box below, you agree to all terms and conditions outlined in this agreement.
              </p>
            </div>
          </div>

          <div>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={agreementRead}
                onChange={(e) => handleChange('agreementRead')(e.target.checked)}
                className="mr-2"
              />
              <span className="text-sm">I have read and agree to the terms and conditions</span>
            </label>
            {errors.agreementRead && <p className="text-red-500 text-xs mt-1">{errors.agreementRead}</p>}
          </div>
          <div>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={isOver18}
                onChange={(e) => handleChange('isOver18')(e.target.checked)}
                className="mr-2"
              />
              <span className="text-sm">I certify that I am over 18 years old</span>
            </label>
            {errors.isOver18 && <p className="text-red-500 text-xs mt-1">{errors.isOver18}</p>}
          </div>
          {errors.submit && <p className="text-red-500 text-sm mt-1">{errors.submit}</p>}
          <div className="flex justify-between mt-6">
            <button
              type="button"
              onClick={prevStep}
              className="px-4 py-2 bg-gray-300 text-gray-800 hover:bg-gray-400 transition duration-300"
            >
              Back
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-black text-white hover:bg-gray-700 transition duration-300"
            >
              Confirm and Proceed to Scan
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Step3;
