import React, { useState } from 'react';

const Step2 = ({ nextStep, prevStep, handleChange, formData }) => {
  const { weight, goal } = formData;
  const [errors, setErrors] = useState({});

  const handleGoalSelect = (selectedGoal) => {
    handleChange('goal')(selectedGoal);
  };

  const handleWeightChange = (e) => {
    handleChange('weight')(e.target.value);
  };

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (!weight || weight <= 0 || weight > 500) {
      errors.weight = 'Please enter a valid weight between 1 and 500 lbs';
      isValid = false;
    }

    if (!goal) {
      errors.goal = 'Please select a goal';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      nextStep();
    }
  };

  return (
    <div className="w-full h-screen flex self-center place-content-center place-items-center">
      <div className="bg-white w-96 text-gray-600 space-y-5 p-4 shadow-xl border">
        <div className="text-center mb-6">
          <h2 className="text-gray-800 text-xl font-semibold sm:text-2xl">Step 2: Your Fitness Goals</h2>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="text-sm text-gray-600 font-bold">Weight (in lbs)</label>
            <input
              type="number"
              placeholder="Weight (in lbs)"
              value={weight}
              onChange={handleWeightChange}
              className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-black shadow-sm transition duration-300"
            />
            {errors.weight && <p className="text-red-500 text-xs mt-1">{errors.weight}</p>}
          </div>
          <div>
            <label className="text-sm text-gray-600 font-bold">Select Your Goal</label>
            <div className="grid grid-cols-1 gap-4">
              {['Bulk', 'Cut', 'Cardio', 'Strength'].map((g) => (
                <button
                  key={g}
                  type="button"
                  onClick={() => handleGoalSelect(g)}
                  className={`w-full px-4 py-2 ${goal === g ? 'bg-black text-white' : 'bg-gray-100 text-gray-800'} transition duration-300 hover:bg-gray-700`}
                >
                  {g}
                </button>
              ))}
            </div>
            {errors.goal && <p className="text-red-500 text-xs mt-1">{errors.goal}</p>}
          </div>
          <div className="flex justify-between">
            <button
              type="button"
              onClick={prevStep}
              className="px-4 py-2 bg-gray-300 text-gray-800 hover:bg-gray-400 transition duration-300"
            >
              Back
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-black text-white hover:bg-gray-700 transition duration-300"
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Step2;
