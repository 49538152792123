import React from 'react';
import Sidebar from './Sidebar';

const Layout = ({ children, openNewScanModal, closeNewScanModal, isNewScanModalOpen }) => {
  return (
    <div className="flex flex-col flex-grow w-full bg-gray-100" style={{ height: '100dvh' }}>
      <div className="flex flex-grow overflow-hidden">
        <Sidebar
          openNewScanModal={openNewScanModal}
          closeNewScanModal={closeNewScanModal}
          isNewScanModalOpen={isNewScanModalOpen}
        />
        <main className="flex-1 overflow-y-auto bg-gray-100 pb-safe">
          <div className="flex flex-col flex-grow pt-safe px-safe pb-safe">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout;



