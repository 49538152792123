import React, { useState, useCallback, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useDropzone } from 'react-dropzone';
import { AiOutlineCamera, AiOutlineClose, AiOutlineCheck } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useScan } from '../../contexts/ScanContext'; // Import the useScan hook

const NewScanModal = ({ isOpen, onClose }) => {
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const modalRef = useRef(null);
  const { refreshScanHistory } = useScan(); // Use the refreshScanHistory function from the ScanContext

  const adjustModalPosition = React.useCallback(() => {
    if (modalRef.current) {
      const sidebar = document.querySelector('.sidebar');
      if (sidebar) {
        const sidebarWidth = sidebar.offsetWidth;
        const availableWidth = window.innerWidth - sidebarWidth;
        const modalWidth = Math.min(availableWidth, 500);
        const leftWidth = isMobile ? sidebarWidth : (sidebarWidth + availableWidth / 2 - modalWidth / 2);
        modalRef.current.style.left = `${leftWidth}px`;
        modalRef.current.style.width = `${availableWidth}px`;
      }
    }
  }, [isMobile]);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    const handleResize = () => {
      checkIfMobile();
      adjustModalPosition();
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [adjustModalPosition]);

  useEffect(() => {
    if (isOpen) {
      adjustModalPosition();
    }
  }, [isOpen, adjustModalPosition]);

  useEffect(() => {
    if (!isOpen) {
      setFiles([]);
    }
  }, [isOpen]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    multiple: false,
    disabled: isUploading
  });

  const removeFile = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    if (files.length === 0) return;

    setIsUploading(true);
    try {
      const storage = getStorage();
      const storageRef = ref(storage, 'scans/' + files[0].name);
      await uploadBytes(storageRef, files[0]);
      const imageUrl = await getDownloadURL(storageRef);

      const functions = getFunctions();
      const scoreBodyScan = httpsCallable(functions, 'scoreBodyScan');
      const result = await scoreBodyScan({ imageUrl });

      console.log('Body scan scores:', result.data);
      setFiles([]);
      await refreshScanHistory(); // Refresh the scan history after successful upload
      onClose();
    } catch (error) {
      console.error('Error uploading and scoring image:', error);
    } finally {
      setIsUploading(false);
    }
  };

  if (!isOpen) return null;

  const modalContent = (
    <div className="bg-black p-6 w-full">
      {/* Guidelines Section */}
      <div className="mb-6 text-white">
        <h3 className="text-lg font-bold mb-4 text-center">GUIDELINES</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <ul className="space-y-2 text-sm">
              <li className="flex items-center whitespace-nowrap">
                <AiOutlineCheck className="text-green-500 mr-2 flex-shrink-0" size={16} />
                <span>Fitted clothing</span>
              </li>
              <li className="flex items-center whitespace-nowrap">
                <AiOutlineCheck className="text-green-500 mr-2 flex-shrink-0" size={16} />
                <span>Good lighting</span>
              </li>
              <li className="flex items-center whitespace-nowrap">
                <AiOutlineCheck className="text-green-500 mr-2 flex-shrink-0" size={16} />
                <span>Neutral background</span>
              </li>
            </ul>
          </div>
          <div>
            <ul className="space-y-2 text-sm">
              <li className="flex items-center whitespace-nowrap">
                <AiOutlineClose className="text-red-500 mr-2 flex-shrink-0" size={16} />
                <span>Loose clothing</span>
              </li>
              <li className="flex items-center whitespace-nowrap">
                <AiOutlineClose className="text-red-500 mr-2 flex-shrink-0" size={16} />
                <span>Blurry photos</span>
              </li>
              <li className="flex items-center whitespace-nowrap">
                <AiOutlineClose className="text-red-500 mr-2 flex-shrink-0" size={16} />
                <span>Photo filters</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {isUploading ? (
        <div className="flex flex-col items-center justify-center h-48">
          <FaSpinner className="animate-spin text-white text-4xl mb-4" />
          <p className="text-white text-lg">Uploading and analyzing...</p>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className="border-2 border-dashed border-gray-600 p-6 text-center mb-4 w-full"
        >
          <input {...getInputProps()} />
          <div className="flex justify-center mb-4">
            <AiOutlineCamera size={32} className="text-white mr-2" />
          </div>
          <p className="text-white text-lg mb-4">Drag a photo here</p>
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4">
            Select from device
          </button>
        </div>
      )}

      {files.length > 0 && !isUploading && (
        <div className="mt-4 max-h-48 overflow-y-auto">
          {files.map((file, index) => (
            <div key={index} className="flex items-center mb-2 bg-gray-900 p-2">
              <div className="w-16 h-16 mr-3 flex-shrink-0">
                <img
                  src={URL.createObjectURL(file)}
                  alt={`Preview ${index}`}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex-grow min-w-0">
                <p className="text-white text-sm truncate">{file.name}</p>
                <p className="text-gray-400 text-xs">{(file.size / 1024 / 1024).toFixed(2)} MB</p>
              </div>
              <button
                onClick={() => removeFile(index)}
                className="ml-2 bg-red-600 text-white p-1 hover:bg-red-700 flex-shrink-0"
              >
                <AiOutlineClose size={16} />
              </button>
            </div>
          ))}
        </div>
      )}

      <div className="mt-6 flex justify-between">
        <button
          onClick={onClose}
          className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4"
          disabled={isUploading}
        >
          Cancel
        </button>
        {files.length > 0 && !isUploading && (
          <button
            onClick={handleUpload}
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4"
          >
            Upload and Analyze
          </button>
        )}
      </div>
    </div>
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75"
          onClick={onClose}
        >
          {isMobile ? (
            <motion.div
              ref={modalRef}
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: "100%" }}
              transition={{ type: "spring", damping: 30, stiffness: 300 }}
              className="fixed bottom-0 left-0 right-0 bg-black max-h-[90vh] overflow-y-auto w-full"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="w-12 h-1 bg-gray-600 mx-auto my-4" />
              {modalContent}
            </motion.div>
          ) : (
            <motion.div
              ref={modalRef}
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ type: "spring", damping: 25, stiffness: 300 }}
              className="absolute bg-black overflow-y-auto max-h-[90vh] max-w-lg"
              onClick={(e) => e.stopPropagation()}
            >
              {modalContent}
            </motion.div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default NewScanModal;
