import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { db } from '../firebase/Init';
import { ref, onValue, remove } from 'firebase/database';
import { useAuth } from './AuthContext';

const ScanContext = createContext();

export const useScan = () => useContext(ScanContext);

export const ScanProvider = ({ children }) => {
  const [scanHistory, setScanHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  const fetchScanHistory = useCallback(() => {
    if (currentUser) {
      const userScansRef = ref(db, `users/${currentUser.uid}/scans`);
      
      return onValue(userScansRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const scansArray = Object.entries(data).map(([id, scan]) => ({
            id,
            ...scan,
          })).sort((a, b) => b.timestamp - a.timestamp);
          setScanHistory(scansArray);
        } else {
          setScanHistory([]);
        }
        setLoading(false);
      });
    } else {
      setScanHistory([]);
      setLoading(false);
      return null;
    }
  }, [currentUser]);

  useEffect(() => {
    const unsubscribe = fetchScanHistory();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [fetchScanHistory]);

  const refreshScanHistory = useCallback(() => {
    setLoading(true);
    return fetchScanHistory();
  }, [fetchScanHistory]);

  const deleteScan = useCallback(async (scanId) => {
    if (currentUser) {
      const scanRef = ref(db, `users/${currentUser.uid}/scans/${scanId}`);
      try {
        await remove(scanRef);
        console.log(`Scan ${scanId} deleted successfully`);
      } catch (error) {
        console.error(`Error deleting scan ${scanId}:`, error);
        throw error;
      }
    }
  }, [currentUser]);

  const value = {
    scanHistory,
    loading,
    refreshScanHistory,
    deleteScan,
  };

  return <ScanContext.Provider value={value}>{children}</ScanContext.Provider>;
};
