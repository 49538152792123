import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { ResponsiveRadar } from '@nivo/radar';
import { ResponsiveLine } from '@nivo/line';
import { Card, CardContent } from "../components/ui/card";
import ScoreCard from '../components/ui/ScoreCard';
import { colorSchemes } from '@nivo/colors';
import { useScan } from '../contexts/ScanContext';
import LoadingGradient from '../components/ui/LoadingGradient';

const Legend = ({ data, colors }) => {
  return (
    <div className="flex flex-wrap justify-center items-start bg-white p-2 rounded-md shadow-md mb-4 font-['Roboto_Condensed']">
      {data.map((item, index) => (
        <div key={item} className="mx-2 my-1">
          <span
            className="text-sm font-semibold"
            style={{ color: colors[index % colors.length] }}
          >
            {item}
          </span>
        </div>
      ))}
    </div>
  );
};

const ScanChart = ({ data }) => {
  if (!data || data.length < 2) {
    return <div>Not enough data for comparison</div>;
  }

  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const lastTwoScans = data.slice(-2);
  const chartData = Object.keys(lastTwoScans[0].scores.Evaluation).map(key => ({
    key,
    [formatDateTime(lastTwoScans[0].timestamp)]: lastTwoScans[0].scores.Evaluation[key].Score,
    [formatDateTime(lastTwoScans[1].timestamp)]: lastTwoScans[1].scores.Evaluation[key].Score,
  }));

  const legendData = [formatDateTime(lastTwoScans[1].timestamp), formatDateTime(lastTwoScans[0].timestamp)];
  const colors = ['#ff6b6b', '#4ecdc4'];

  return (
    <div className="w-full h-full flex flex-col items-center justify-center p-4">
      <Legend data={legendData} colors={colors} />
      <div style={{ width: '100%', height: 'calc(100% - 50px)' }}>
        <ResponsiveRadar
          data={chartData}
          keys={legendData}
          indexBy="key"
          maxValue={10}
          margin={{ top: 20, right: 80, bottom: 40, left: 80 }}
          curve="linearClosed"
          borderWidth={2}
          borderColor={{ from: 'color' }}
          gridLabelOffset={15}
          dotSize={8}
          dotColor={{ theme: 'background' }}
          dotBorderWidth={2}
          colors={colors}
          blendMode="multiply"
          motionConfig="wobbly"
          gridLevels={3}
          gridLabels={(value) => (value % 5 === 0 ? value : '')}
          theme={{
            fontSize: 11,
            textColor: '#333333',
          }}
        />
      </div>
    </div>
  );
};

const ScansOverTime = ({ data }) => {
  if (!data || data.length === 0) {
    return <div>No scan data available</div>;
  }

  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} ${date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}`;
  };

  // Sort the data chronologically
  const sortedData = [...data].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

  const chartData = Object.keys(sortedData[0].scores.Evaluation).map(key => ({
    id: key,
    data: sortedData.map(scan => ({
      x: formatDateTime(scan.timestamp),
      y: scan.scores.Evaluation[key].Score
    }))
  }));

  const colors = colorSchemes.category10;

  return (
    <div className="w-full h-full flex flex-col items-center justify-center p-4">
      <Legend data={chartData.map(item => item.id)} colors={colors} />
      <div style={{ width: '100%', height: 'calc(100% - 50px)' }}>
        <ResponsiveLine
          data={chartData}
          margin={{ top: 20, right: 10, bottom: 50, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{ type: 'linear', min: 0, max: 10, stacked: false, reverse: false }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Date',
            legendOffset: 40,
            legendPosition: 'middle'
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Score',
            legendOffset: -40,
            legendPosition: 'middle'
          }}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[]}
          colors={colors}
          theme={{
            fontSize: 20,
            textColor: '#333333',
          }}
        />
      </div>
    </div>
  );
};

const NoScanHistory = () => (
  <div className="flex flex-col items-center justify-center h-full text-gray-500">
    <svg className="w-16 h-16 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
    <p className="text-xl font-bold mb-2">No Scan History</p>
    <p className="text-center">Take your first scan to start tracking your progress!</p>
  </div>
);

const History = () => {
  const { scanHistory, loading, deleteScan } = useScan();
  const [activeTab, setActiveTab] = useState('history');
  const [isMobile, setIsMobile] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedScans, setSelectedScans] = useState([]);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const handleEdit = () => {
    setIsEditing(!isEditing);
    setSelectedScans([]);
  };

  const handleSelectScan = (scanId) => {
    setSelectedScans(prev =>
      prev.includes(scanId)
        ? prev.filter(id => id !== scanId)
        : [...prev, scanId]
    );
  };

  const handleDelete = async () => {
    if (window.confirm(`Are you sure you want to delete ${selectedScans.length} scan(s)?`)) {
      for (let scanId of selectedScans) {
        await deleteScan(scanId);
      }
      setSelectedScans([]);
      setIsEditing(false);
    }
  };

  const SectionTitle = ({ children, showEditButton = false }) => (
    <div className="flex justify-between items-center mb-2">
      <h2 className="text-xl font-bold uppercase">{children}</h2>
      {showEditButton && scanHistory.length > 0 && (
        <button
          onClick={handleEdit}
          className={`
            group flex items-center justify-center border rounded-md px-3 py-1 transition-colors duration-200
            ${isEditing
              ? 'text-gray-600 hover:bg-red-500 border-gray-300 hover:border-red-500'
              : 'text-gray-600 hover:bg-green-500 border-gray-300 hover:border-green-500'
            }
          `}
        >
          <AiOutlineEdit
            size={20}
            className="mr-2 text-gray-600 transition-colors duration-200 group-hover:text-white"
          />
          <span className="transition-colors duration-200 group-hover:text-white">
            {isEditing ? 'Cancel' : 'Edit'}
          </span>
        </button>
      )}
    </div>
  );

  const renderContent = () => {
    if (loading) {
      return <LoadingGradient className="h-full w-full rounded" />;
    }

    if (scanHistory.length === 0) {
      return <NoScanHistory />;
    }

    if (isMobile) {
      switch (activeTab) {
        case 'history':
          return (
            <div className="h-full flex flex-col">
              <Card className="flex-grow overflow-hidden">
                <CardContent className="h-full flex flex-col">
                  <SectionTitle showEditButton={true}>Scan History</SectionTitle>
                  <div className="custom-scrollbar bg-gray-100 rounded-lg p-0 flex-grow overflow-y-auto">
                    {scanHistory.map((scan) => (
                      <motion.div
                        key={scan.id}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        className="relative"
                      >
                        {isEditing && (
                          <input
                            type="checkbox"
                            checked={selectedScans.includes(scan.id)}
                            onChange={() => handleSelectScan(scan.id)}
                            className="absolute top-2 left-2 z-10 w-5 h-5"
                          />
                        )}
                        <ScoreCard
                          imageUrl={scan.imageUrl}
                          timestamp={scan.timestamp}
                          scores={scan.scores}
                        />
                      </motion.div>
                    ))}
                  </div>
                </CardContent>
              </Card>
              {isEditing && selectedScans.length > 0 && (
                <div className="mt-4">
                  <button
                    onClick={handleDelete}
                    className="w-full flex items-center justify-center bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                  >
                    <AiOutlineDelete size={20} className="mr-2" />
                    DELETE SELECTED ({selectedScans.length})
                  </button>
                </div>
              )}
            </div>
          );
        case 'chart':
          return (
            <Card className="h-full">
              <CardContent className="h-full flex flex-col">
                <SectionTitle>Scan Chart</SectionTitle>
                <div className="flex-grow">
                  <ScanChart data={scanHistory} />
                </div>
              </CardContent>
            </Card>
          );
        case 'graph':
          return (
            <Card className="h-full">
              <CardContent className="h-full flex flex-col">
                <SectionTitle>Scans Over Time</SectionTitle>
                <div className="flex-grow">
                  <ScansOverTime data={scanHistory} />
                </div>
              </CardContent>
            </Card>
          );
        default:
          return null;
      }
    } else {
      return (
        <div className="flex gap-4 h-full">
          <div className="w-2/5 h-full">
            <Card className="h-full">
              <CardContent className="h-full flex flex-col">
                <SectionTitle showEditButton={true}>Scan History</SectionTitle>
                <div className="custom-scrollbar bg-gray-100 rounded-lg p-0 flex-grow overflow-y-auto relative">
                  {scanHistory.map((scan) => (
                    <motion.div
                      key={scan.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                      className="relative"
                    >
                      {isEditing && (
                        <input
                          type="checkbox"
                          checked={selectedScans.includes(scan.id)}
                          onChange={() => handleSelectScan(scan.id)}
                          className="absolute top-2 left-2 z-10 w-5 h-5"
                        />
                      )}
                      <ScoreCard
                        imageUrl={scan.imageUrl}
                        timestamp={scan.timestamp}
                        scores={scan.scores}
                      />
                    </motion.div>
                  ))}
                </div>
                {isEditing && selectedScans.length > 0 && (
                  <div className="mt-4">
                    <button
                      onClick={handleDelete}
                      className="w-full flex items-center justify-center bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                    >
                      <AiOutlineDelete size={20} className="mr-2" />
                      DELETE SELECTED ({selectedScans.length})
                    </button>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
          <div className="w-3/5 h-full flex flex-col gap-4">
            <Card className="h-1/2">
              <CardContent className="h-full flex flex-col">
                <SectionTitle>Scan Chart</SectionTitle>
                <div className="flex-grow">
                  <ScanChart data={scanHistory} />
                </div>
              </CardContent>
            </Card>
            <Card className="h-1/2">
              <CardContent className="h-full flex flex-col">
                <SectionTitle>Scans Over Time</SectionTitle>
                <div className="flex-grow">
                  <ScansOverTime data={scanHistory} />
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      );
    }
  };

  const TabButton = ({ name, label }) => (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className={`flex-1 px-4 py-2 font-semibold 
        ${activeTab === name
          ? 'bg-white text-black border-2 border-black'
          : 'bg-black text-white'
        }`}
      onClick={() => setActiveTab(name)}
    >
      {label}
    </motion.button>
  );

  return (
    <div className="container mx-auto p-4 flex flex-col h-4/5" style={{ height: '100dvh' }}>
      <div className="flex justify-between items-center mb-4">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-3xl font-bold uppercase">HISTORY</h1>
        </motion.div>
      </div>
      <div className="flex-grow overflow-hidden flex flex-col">
        {isMobile && (
          <div className="flex bg-black mb-4 overflow-hidden">
            <TabButton name="history" label="HISTORY" />
            <TabButton name="chart" label="CHART" />
            <TabButton name="graph" label="GRAPH" />
          </div>
        )}
        <AnimatePresence mode="wait">
          {renderContent()}
        </AnimatePresence>
      </div>
    </div >
  );
};

export default History;
