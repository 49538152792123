import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Card, CardContent } from "../components/ui/card";

const Account = () => {
  const { currentUser, deleteAccount, isEmailUser } = useAuth();
  const [isDeleting, setIsDeleting] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleDeleteAccount = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete your account? This action cannot be undone.")) {
      setIsDeleting(true);
      setError('');
      try {
        await deleteAccount(isEmailUser ? password : null);
        navigate('/login');
      } catch (error) {
        setError('Failed to delete account. Please try again.');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <div className="container mx-auto p-4 flex flex-col font-['Roboto_Condensed']  style={{ height: '100dvh' }">
      <h1 className="text-3xl font-bold mb-4 uppercase">ACCOUNT</h1>
      <Card className="mb-4">
        <CardContent className="p-6">
          <h2 className="text-xl font-semibold mb-2">Account Information</h2>
          <p><strong>Email:</strong> {currentUser.email}</p>
          <p><strong>Display Name:</strong> {currentUser.displayName || 'Not set'}</p>
          <p><strong>Authentication Method:</strong> {isEmailUser ? 'Email/Password' : 'Google'}</p>
        </CardContent>
      </Card>
      <Card>
        <CardContent className="p-6">
          <h2 className="text-xl font-semibold mb-2 text-red-600">Danger Zone</h2>
          <p className="mb-4">Deleting your account will permanently remove all your data. This action cannot be undone.</p>
          <form onSubmit={handleDeleteAccount}>
            {isEmailUser && (
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password to confirm"
                className="w-full p-2 mb-4 border rounded"
                required
              />
            )}
            {error && <p className="text-red-500 mb-2">{error}</p>}
            <button
              type="submit"
              disabled={isDeleting}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 disabled:bg-red-300"
            >
              {isDeleting ? 'Deleting...' : 'Delete Account'}
            </button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default Account;
