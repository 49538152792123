import React from 'react';

const LoadingGradient = ({ className }) => {
  return (
    <div className={`animate-pulse bg-gradient-to-r from-gray-300 to-gray-200 ${className}`}>
      &nbsp;
    </div>
  );
};

export default LoadingGradient;
