import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/CustomDatePicker.css";
import { FaMars, FaVenus, FaGenderless } from 'react-icons/fa';

const Step1 = ({ nextStep, handleChange, formData }) => {
  const { birthday, heightFeet, heightInches, gender } = formData;
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (!birthday) {
      errors.birthday = 'Please select your birthday';
      isValid = false;
    }

    if (!heightFeet || heightFeet < 1 || heightFeet > 9) {
      errors.heightFeet = 'Please enter a valid height (feet) between 1 and 9';
      isValid = false;
    }

    if (!heightInches || heightInches < 0 || heightInches > 11) {
      errors.heightInches = 'Please enter a valid height (inches) between 0 and 11';
      isValid = false;
    }

    if (!gender) {
      errors.gender = 'Please select your gender';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      nextStep();
    }
  };

  const handleDateChange = (date) => {
    handleChange('birthday')(date);
  };

  const GenderOption = ({ value, icon, label }) => (
    <label className={`flex flex-col items-center justify-center p-4 border cursor-pointer transition-colors duration-200 ${gender === value ? 'bg-gray-100 border-black' : 'hover:bg-gray-100'}`}>
      <input
        type="radio"
        name="gender"
        value={value}
        checked={gender === value}
        onChange={(e) => handleChange('gender')(e.target.value)}
        className="sr-only"
      />
      {icon}
      <span className="mt-2 text-sm font-medium">{label}</span>
    </label>
  );

  return (
    <div className="w-full h-screen flex self-center place-content-center place-items-center">
      <div className="bg-white w-96 text-gray-600 space-y-5 p-4 shadow-xl border">
        <div className="text-center mb-6">
          <h2 className="text-gray-800 text-xl font-semibold sm:text-2xl">Step 1: Tell us about yourself</h2>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="text-sm text-gray-600 font-bold">Birthday</label>
            <div className="relative">
              <DatePicker
                selected={birthday}
                onChange={handleDateChange}
                dateFormat="MMMM d, yyyy"
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                placeholderText="Select your birthday"
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-white border border-gray-300 focus:outline-none focus:border-black focus:ring-2 focus:ring-black focus:ring-opacity-50"
                wrapperClassName="w-full"
                popperClassName="react-datepicker-right"
              />
              <div className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-400 pointer-events-none">
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
            {errors.birthday && <p className="text-red-500 text-xs mt-1">{errors.birthday}</p>}
          </div>
          <div className="flex space-x-4">
            <div className="flex-1">
              <label className="text-sm text-gray-600 font-bold">Height (feet)</label>
              <input
                type="number"
                placeholder="Feet"
                value={heightFeet}
                onChange={(e) => handleChange('heightFeet')(e.target.value)}
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-white border border-gray-300 focus:outline-none focus:border-black focus:ring-2 focus:ring-black focus:ring-opacity-50"
              />
              {errors.heightFeet && <p className="text-red-500 text-xs mt-1">{errors.heightFeet}</p>}
            </div>
            <div className="flex-1">
              <label className="text-sm text-gray-600 font-bold">Height (inches)</label>
              <input
                type="number"
                placeholder="Inches"
                value={heightInches}
                onChange={(e) => handleChange('heightInches')(e.target.value)}
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-white border border-gray-300 focus:outline-none focus:border-black focus:ring-2 focus:ring-black focus:ring-opacity-50"
              />
              {errors.heightInches && <p className="text-red-500 text-xs mt-1">{errors.heightInches}</p>}
            </div>
          </div>
          <div>
            <label className="text-sm text-gray-600 font-bold">Gender</label>
            <div className="grid grid-cols-3 gap-4 mt-2">
              <GenderOption value="male" icon={<FaMars size={24} className="text-blue-500" />} label="Male" />
              <GenderOption value="female" icon={<FaVenus size={24} className="text-pink-500" />} label="Female" />
              <GenderOption value="other" icon={<FaGenderless size={24} className="text-purple-500" />} label="Other" />
            </div>
            {errors.gender && <p className="text-red-500 text-xs mt-1">{errors.gender}</p>}
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 text-white font-medium bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50 transition duration-300"
          >
            Next
          </button>
        </form>
      </div>
    </div>
  );
};

export default Step1;
