import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Card, CardContent } from "../components/ui/card";
import { useScan } from '../contexts/ScanContext';
import LoadingGradient from '../components/ui/LoadingGradient';
const AnimatedDial = ({ score }) => {
	const normalizedScore = score / 10; // Convert score to a 0-1 range
	const endAngle = -180 + (180 * normalizedScore); // Calculate end angle based on score

	// Generate the arc path
	const generateArcPath = (start, end) => {
		const startAngle = start * (Math.PI / 180);
		const endAngle = end * (Math.PI / 180);
		const rx = 90;
		const ry = 90;
		const xAxisRotation = 0;
		const largeArcFlag = end - start <= 180 ? "0" : "1";
		const sweepFlag = 1;
		const x1 = 100 + rx * Math.cos(startAngle);
		const y1 = 110 + ry * Math.sin(startAngle);
		const x2 = 100 + rx * Math.cos(endAngle);
		const y2 = 110 + ry * Math.sin(endAngle);

		return `M ${x1} ${y1} A ${rx} ${ry} ${xAxisRotation} ${largeArcFlag} ${sweepFlag} ${x2} ${y2}`;
	};

	return (
		<svg className="w-full" viewBox="0 0 200 120">
			{/* Background Arc */}
			<path
				d={generateArcPath(-180, 0)}
				fill="none"
				stroke="#333"
				strokeWidth="4"
				strokeDasharray="4 4"
			/>
			{/* Score Arc */}
			<motion.path
				d={generateArcPath(-180, endAngle)}
				fill="none"
				stroke="white"
				strokeWidth="4"
				initial={{ pathLength: 0 }}
				animate={{ pathLength: 1 }}
				transition={{ duration: 1, ease: "easeOut" }}
			/>
			{/* Tick marks */}
			{[...Array(81)].map((_, i) => {
				const angle = -180 + i * 4.5;
				if (angle <= endAngle) {
					const x1 = 100 + 85 * Math.cos(angle * Math.PI / 180);
					const y1 = 110 + 85 * Math.sin(angle * Math.PI / 180);
					const x2 = 100 + (i % 2 === 0 ? 75 : 80) * Math.cos(angle * Math.PI / 180);
					const y2 = 110 + (i % 2 === 0 ? 75 : 80) * Math.sin(angle * Math.PI / 180);
					return (
						<motion.line
							key={i}
							x1={x1}
							y1={y1}
							x2={x2}
							y2={y2}
							stroke="white"
							strokeWidth={i % 2 === 0 ? "2" : "1"}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.05, delay: i * 0.01, ease: "easeOut" }}
						/>
					);
				}
				return null;
			})}
			{/* Score */}
			<motion.text
				x="100"
				y="90"
				textAnchor="middle"
				fill="white"
				fontSize="48"
				fontWeight="bold"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.5, delay: 0.8, ease: "easeOut" }}
			>
				<motion.tspan
					initial={{ y: 20 }}
					animate={{ y: 0 }}
					transition={{ duration: 0.5, delay: 0.8, ease: "easeOut" }}
				>
					{score}
				</motion.tspan>
			</motion.text>
			{/* Overall text */}
			<motion.text
				x="100"
				y="115"
				textAnchor="middle"
				fill="white"
				fontSize="16"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.5, delay: 1.3, ease: "easeOut" }}
			>
				OVERALL
			</motion.text>
		</svg>
	);
};

const ScoreBar = ({ name, score, onSelect, isSelected }) => (
	<div
		className={`flex items-center mb-4 cursor-pointer transition-colors duration-200 ${isSelected ? 'bg-gray-100' : 'hover:bg-gray-50'}`}
		onClick={() => onSelect(name)}
	>
		<div className="flex items-center w-full p-2">
			<div className="flex flex-col w-20 sm:w-24 mr-2 flex-shrink-0">
				<div className={`text-white text-center py-1 px-2 ${isSelected ? 'bg-black' : 'bg-gray-800'}`}>
					{score.toFixed(1)}
				</div>
				<div className="bg-gray-200 text-gray-800 text-center py-1 px-2 text-xs">
					{name}
				</div>
			</div>
			<div className="flex-grow mx-2">
				<div className="bg-gray-200 h-6 overflow-hidden">
					<div
						className={`h-full transition-all duration-300 ease-out ${isSelected ? 'bg-black' : 'bg-gray-800'}`}
						style={{ width: `${score * 10}%` }}
					/>
				</div>
			</div>
			<div className={`w-20 sm:w-24 md:w-28 text-right flex-shrink-0 flex items-center justify-end ${isSelected ? 'text-black font-semibold' : 'text-gray-500'}`}>
				<span className="text-[10px] sm:text-xs md:text-sm mr-1">SEE DETAILS</span>
				<svg
					className="w-3 h-3 sm:w-4 sm:h-4"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M9 18L15 12L9 6"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
		</div>
	</div>
);
const FeedbackModal = ({ category, isOpen, onClose }) => {
	const modalRef = useRef(null);

	useEffect(() => {
		const handleResize = () => {
			if (modalRef.current) {
				const sidebar = document.querySelector('.sidebar');
				if (sidebar) {
					const sidebarWidth = sidebar.offsetWidth;
					const availableWidth = window.innerWidth - sidebarWidth;
					const modalWidth = Math.min(availableWidth, 500);

					modalRef.current.style.left = `${sidebarWidth}px`;
					modalRef.current.style.width = `${modalWidth}px`;
				}
			}
		};

		handleResize(); // Initial positioning
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [isOpen]);

	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					className="fixed inset-0 bg-black bg-opacity-50 z-50"
					onClick={onClose}
				>
					<motion.div
						ref={modalRef}
						initial={{ y: "100%" }}
						animate={{ y: 0 }}
						exit={{ y: "100%" }}
						transition={{ type: "spring", damping: 30, stiffness: 300 }}
						className="absolute bottom-0 bg-white rounded-t-lg p-6 max-h-[90vh] overflow-y-auto"
						onClick={(e) => e.stopPropagation()}
					>
						<div className="w-12 h-1 bg-gray-300 rounded mx-auto mb-4" />
						<h2 className="text-xl font-bold font-condensed mb-4 flex items-center uppercase">
							{category.name}

						</h2>
						<div className="mb-4">
							<h3 className="font-light font-condensed mb-2 bg-gray-200 px-2 py-1 uppercase">FEEDBACK</h3>
							<p className="text-sm border p-2">{category.feedback}</p>
						</div>
						<div>
							<h3 className="font-light font-condensed mb-2 uppercase">TIPS</h3>
							<ul className="list-none space-y-4 relative">
								<div className="absolute left-1.5 top-0 bottom-0 w-px bg-gray-300"></div>
								{category.tips.map((tip, index) => (
									<li key={index} className="pl-6 relative">
										<div className="absolute left-0 top-2 w-3 h-3 bg-black rounded-full"></div>
										{tip}
									</li>
								))}
							</ul>
						</div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

const NoScanWarning = () => (
	<div className="flex flex-col items-center justify-center h-full text-gray-500 font-['Roboto_Condensed']">
		<svg className="w-16 h-16 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
		</svg>
		<p className="text-xl font-bold mb-2">No Scans Available</p>
		<p className="text-center">Take a new scan to see your results!</p>
	</div>
);

const Home = () => {
	const { scanHistory, loading } = useScan();
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		if (scanHistory.length > 0) {
			const categories = Object.keys(scanHistory[0].scores.Evaluation);
			if (categories.length > 0) {
				setSelectedCategory(categories[0]);
			}
		}

		const checkIfMobile = () => {
			setIsMobile(window.innerWidth < 768);
		};

		checkIfMobile();
		window.addEventListener('resize', checkIfMobile);

		return () => window.removeEventListener('resize', checkIfMobile);
	}, [scanHistory]);

	const renderContent = () => {
		if (loading) {
			return <LoadingGradient className="h-full w-full rounded" />;
		}

		if (scanHistory.length === 0) {
			return (
				<div className="flex flex-col md:flex-row gap-6 flex-grow">
					<div className="flex-1 flex flex-col gap-4">
						<Card className="bg-black">
							<CardContent className="p-6">
								<NoScanWarning />
							</CardContent>
						</Card>
						<Card className="bg-white flex-grow">
							<CardContent className="p-6 flex items-center justify-center">
								<p className="text-gray-500 text-center">Complete a scan to see your scores</p>
							</CardContent>
						</Card>
					</div>
					<div className="flex-1">
						<Card className="h-full">
							<CardContent className="p-6 flex items-center justify-center">
								<p className="text-gray-500 text-center">Scan details will appear here</p>
							</CardContent>
						</Card>
					</div>
				</div>
			);
		}

		const mostRecentScan = scanHistory[0];
		const overallScore = mostRecentScan.scores["Overall Score"];

		const categories = Object.entries(mostRecentScan.scores.Evaluation).map(([name, data]) => ({
			name,
			score: data.Score,
			feedback: data.Feedback,
			tips: data.Tips
		}));

		const selectedCategoryData = mostRecentScan.scores.Evaluation[selectedCategory];

		return (
			<div className="flex flex-col md:flex-row gap-6 flex-grow">
				<div className="flex-1 flex flex-col gap-4">
					<Card className="bg-black">
						<CardContent className="p-6">
							<AnimatedDial score={parseFloat(overallScore.toFixed(1))} />
						</CardContent>
					</Card>
					<Card className="bg-white flex-grow">
						<CardContent className="p-0">
							{categories.map((category) => (
								<ScoreBar
									key={category.name}
									name={category.name}
									score={category.score}
									onSelect={handleCategorySelect}
									isSelected={category.name === selectedCategory}
								/>
							))}
						</CardContent>
					</Card>
				</div>
				{!isMobile && selectedCategoryData && (
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.5, delay: 0.2 }}
						className="flex-1"
					>
						<Card className="h-full">
							<CardContent className="p-6 flex flex-col h-full">
								<h2 className="text-3xl font-bold font-condensed mb-4 flex items-center uppercase">
									{selectedCategory}
									<span className="ml-2 text-gray-400">▲</span>
								</h2>
								<div className="mb-4">
									<h3 className="text-3xl font-light font-condensed mb-2 bg-gray-200 px-2 py-1 uppercase">FEEDBACK</h3>
									<p className="border font-light font-condensed p-2">{selectedCategoryData.Feedback}</p>
								</div>
								<div className="flex-grow">
									<h3 className="text-3xl font-light font-condensed mb-2 uppercase">TIPS</h3>
									<ul className="list-disc pl-5 font-light font-condensed">
										{selectedCategoryData.Tips.map((tip, index) => (
											<li key={index} className="mb-2">{tip}</li>
										))}
									</ul>
								</div>
								<div className="text-xs text-gray-500 mt-4 flex items-center">
									<span className="mr-2">ⓘ</span>
									models will continue to improve as more training data is collected.
								</div>
							</CardContent>
						</Card>
					</motion.div>
				)}
			</div>
		);
	};

	const handleCategorySelect = (name) => {
		setSelectedCategory(name);
		if (isMobile) {
			setIsModalOpen(true);
		}
	};

	return (
		<div className="container mx-auto p-4 font-sans flex flex-col font-['Roboto_Condensed']" style={{ height: '100dvh' }}>
			<div className="flex mb-4 justify-between items-center">
				<motion.div
					initial={{ opacity: 0, y: -20 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.5 }}
				>
					<h1 className="text-3xl font-bold uppercase">HOME</h1>
				</motion.div>
				{loading ? (
					<LoadingGradient className="h-6 w-24 rounded" />
				) : scanHistory.length > 0 ? (
					<div className="text-xl text-gray-500">{new Date(scanHistory[0].timestamp).toLocaleDateString()}</div>
				) : (
					<div className="text-xl text-gray-500">{new Date().toLocaleDateString()}</div>
				)}
			</div>
			{renderContent()}
			<FeedbackModal
				category={{
					name: selectedCategory,
					feedback: scanHistory.length > 0 && selectedCategory
						? scanHistory[0].scores.Evaluation[selectedCategory]?.Feedback
						: '',
					tips: scanHistory.length > 0 && selectedCategory
						? scanHistory[0].scores.Evaluation[selectedCategory]?.Tips || []
						: []
				}}
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
			/>
		</div>
	);
};

export default Home;
