import { auth, db } from "./Init";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
  deleteUser,
  reauthenticateWithCredential,
  EmailAuthProvider
} from "firebase/auth";
import { ref, set, get, update, remove } from "firebase/database";

export const createUserDocument = async (user, additionalData = {}) => {
  if (!user) return;

  const userRef = ref(db, 'users/' + user.uid);
  const snapshot = await get(userRef);

  if (!snapshot.exists()) {
    const { email } = user;
    const createdAt = new Date();

    try {
      await set(userRef, {
        email,
        createdAt,
        hasSeenIntro: false, // Initialize the intro state
        hasCompletedOnboarding: false, // You might want to track this too
        ...additionalData
      });
    } catch (error) {
      console.error('Error creating user document', error);
    }
  }

  return userRef;
};

export const doCreateUserWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Create user data in Realtime Database
    const userRef = ref(db, 'users/' + user.uid);
    await set(userRef, {
      uid: user.uid,
      email: user.email,
      createdAt: new Date().toISOString(),
      hasSeenIntro: false,
      onboardingComplete: false
    });

    return userCredential;
  } catch (error) {
    console.error("Error in createUserWithEmailAndPassword: ", error);
    throw error;
  }
};

export const doSignInWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Update lastSignInTime in Realtime Database
    const userRef = ref(db, `users/${user.uid}`);
    await update(userRef, {
      lastSignInTime: new Date().toISOString()  // Add this line
    });

    return user;
  } catch (error) {
    console.error("Error in signInWithEmailAndPassword: ", error);
    throw error;
  }
};

export const doSignInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    const userRef = ref(db, 'users/' + user.uid);
    const snapshot = await get(userRef);

    const userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      lastSignInTime: new Date().toISOString(),
      createdAt: snapshot.exists() ? snapshot.val().createdAt : new Date().toISOString(),
      onboardingComplete: snapshot.exists() ? snapshot.val().onboardingComplete : false,
      hasSeenIntro: snapshot.exists() ? snapshot.val().hasSeenIntro : false
    };

    if (!snapshot.exists()) {
      await set(userRef, userData);
      console.log('New user created in Realtime Database');
    } else {
      await update(userRef, userData);
      console.log('Existing user data updated');
    }

    // Force token refresh to ensure latest permissions
    await user.getIdToken(true);

    return { user };
  } catch (error) {
    console.error('Error during Google sign-in:', error);
    throw error;
  }
};

export const doSignOut = () => {
  return auth.signOut();
};

export const doPasswordReset = (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const doPasswordChange = (password) => {
  return updatePassword(auth.currentUser, password);
};

export const doSendEmailVerification = () => {
  return sendEmailVerification(auth.currentUser, {
    url: `${window.location.origin}/home`,
  });
};

export const doUpdateProfile = async (displayName, photoURL) => {
  try {
    const user = auth.currentUser;
    await updateProfile(user, { displayName, photoURL });

    // Update the Realtime Database as well
    const userRef = ref(db, 'users/' + user.uid);
    await update(userRef, {
      displayName,
      photoURL,
      lastUpdated: new Date().toISOString()
    });

    return user;
  } catch (error) {
    console.error("Error updating profile: ", error);
    throw error;
  }
};

export const doDeleteAccount = async (password = null) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("No user is currently signed in.");
  }

  try {
    const isEmailUser = user.providerData.some(
      (provider) => provider.providerId === "password"
    );

    if (isEmailUser && password) {
      // Re-authenticate with email and password
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);
    }

    // Delete user data from Realtime Database
    await remove(ref(db, `users/${user.uid}`));

    // Delete the user account
    await deleteUser(user);

    console.log("User account deleted successfully");
  } catch (error) {
    console.error("Error deleting account:", error);
    throw error;
  }
};
