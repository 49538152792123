import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { doSignOut } from '../firebase/Auth';
import {
  AiOutlineHome,
  AiOutlineHistory,
  AiOutlinePlus,
  AiOutlineUser,
  AiOutlineLogout
} from 'react-icons/ai';
import logoImage from '../assets/logo.png';
import logoCondensed from '../assets/logo-condensed.png';
import Joyride, { STATUS } from 'react-joyride';
import { ref, get, update } from 'firebase/database';
import { db } from '../firebase/Init';

const Sidebar = ({ openNewScanModal, closeNewScanModal, isNewScanModalOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userLoggedIn, currentUser } = useAuth();
  const [isNewScanActive, setIsNewScanActive] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [runTour, setRunTour] = useState(false);

  const steps = [
    {
      target: '.new-scan-button',
      content: 'Click here to start your first body scan! Upload a photo and our AI will analyze your physique.',
      placement: 'right',
      disableBeacon: true
    },
    {
      target: '.history-button',
      content: 'View all your previous scans and track your progress over time.',
      placement: 'right'
    },
    {
      target: '.account-button',
      content: 'Manage your account settings and preferences here.',
      placement: 'right'
    }
  ];

  useEffect(() => {
    if (!isNewScanModalOpen) {
      setIsNewScanActive(false);
      setHoveredItem(null);
    } else {
      setIsNewScanActive(true);
    }
  }, [isNewScanModalOpen]);

  useEffect(() => {
    const checkFirstTimeUser = async () => {
      if (currentUser) {
        const userRef = ref(db, `users/${currentUser.uid}`);
        const snapshot = await get(userRef);

        if (snapshot.exists()) {
          const userData = snapshot.val();
          if (!userData.hasSeenIntro) {
            setRunTour(true);
          }
        }
      }
    };

    checkFirstTimeUser();
  }, [currentUser]);

  const handleHover = (label) => {
    setHoveredItem(null);

    if (window.matchMedia('(hover: hover)').matches) {
      setHoveredItem(label);
    }
  };

  const handleJoyrideCallback = async (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
      if (currentUser) {
        const userRef = ref(db, `users/${currentUser.uid}`);
        await update(userRef, {
          hasSeenIntro: true
        });
      }
    }
  };

  const NavItem = ({ to, icon, label, onClick, isActive, isNewScan, className }) => {
    const isSelected = isActive || (to === location.pathname && !isNewScan);

    return (
      <NavLink
        to={to}
        className={`flex items-center p-4 transition-colors duration-200
          ${isSelected && !runTour ? 'bg-gray-100 text-black' : 'text-white'}
          ${!isSelected && hoveredItem === label ? 'bg-gray-800 text-white' : ''}
          ${className}`}
        onClick={(e) => {
          if (onClick) {
            e.preventDefault();
            onClick();
          }
          if (isNewScan) {
            setIsNewScanActive(true);
          } else {
            setIsNewScanActive(false);
            closeNewScanModal();
          }
          setHoveredItem(null);
        }}
        onMouseEnter={() => !isSelected && handleHover(label)}
        onMouseLeave={() => setHoveredItem(null)}
      >
        <div className={`flex items-center justify-center w-8 h-8 rounded-full transition-colors duration-200
          ${isSelected && !runTour ? 'font-bold' : ''}`}>
          {React.cloneElement(icon, {
            strokeWidth: isSelected && !runTour ? 2 : 1,
            className: isSelected && !runTour ? 'text-black' : 'text-white'
          })}
        </div>
        <span className={`ml-3 hidden md:inline ${isSelected && !runTour ? 'font-bold' : ''}`}>{label}</span>
      </NavLink>
    );
  };

  return (
    <>
      <div className="sidebar bg-black text-white  w-16 md:w-64 flex flex-col transition-all duration-300 font-sans">
        <div className="p-4 flex items-center justify-center md:justify-start">
          <img src={logoImage} alt="MOGGED" className="hidden md:block h-12 w-auto" />
          <img src={logoCondensed} alt="MOGGED" className="md:hidden h-8 w-auto" />
        </div>
        <nav className="flex-grow mt-4">
          <NavItem to="/home" icon={<AiOutlineHome size={24} />} label="HOME" />
          <NavItem
            to="/history"
            icon={<AiOutlineHistory size={24} />}
            label="HISTORY"
            className="history-button"
          />
          <NavItem
            to="#"
            icon={<AiOutlinePlus size={24} />}
            label="NEW SCAN"
            onClick={openNewScanModal}
            isActive={isNewScanActive}
            isNewScan={true}
            className="new-scan-button"
          />
        </nav>
        <div className="mt-auto">
          <NavItem
            to="/account"
            icon={<AiOutlineUser size={24} />}
            label="ACCOUNT"
            className="account-button"
          />
          {userLoggedIn && (
            <button
              onClick={() => doSignOut().then(() => navigate('/login'))}
              className={`flex items-center p-4 w-full transition-colors duration-200 text-white
                ${hoveredItem === 'LOGOUT' ? 'bg-gray-800' : ''}`}
              onMouseEnter={() => handleHover('LOGOUT')}
              onMouseLeave={() => handleHover(null)}
            >
              <div className="flex items-center justify-center w-8 h-8 rounded-full transition-colors duration-200">
                <AiOutlineLogout size={24} />
              </div>
              <span className="ml-3 hidden md:inline">LOGOUT</span>
            </button>
          )}
        </div>
      </div>

      <Joyride
        steps={steps}
        run={runTour}
        continuous
        locale={{
          last: "Done"
        }}
        styles={{
          options: {
            primaryColor: '#3B82F6', // blue-500
            textColor: '#1F2937', // gray-800
            backgroundColor: '#FFFFFF',
          }
        }}
        callback={handleJoyrideCallback}
      />
    </>
  );
};

export default Sidebar;
